window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

// Neils additions

// Get the current URL to send to partner
const currentURL = window.location.href;

// Find the hidden input field by its ID
const hiddenInputField = document.getElementById('current_url');

// Set the value of the hidden input field to the current URL
if (hiddenInputField) {
  hiddenInputField.value = currentURL;
} else {
  console.error("Hidden input field not found."); 
}

// IP Address Capture
const fetchIpAddress = async () => {
    // Get IP Address from an external link to fetch the global IP
    const getIPAddress = async () => {
      try {
        const response = await fetch(
          "https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp"
        );
        const data = await response.json();
        if (data.ip) {
          return data.ip;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    try {
      const ipInputField = document.querySelector(
        `[data-variable="ipAddress"]`
      );

      if (ipInputField != null) {
        const ipAddress = await getIPAddress();
        ipInputField.value = ipAddress;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  
  window.addEventListener("heyflow-init", event => {
      fetchIpAddress();
  });
  
  